import { Trans } from 'next-i18next';
import { ReactNode } from 'react';

import { transformToCurrency } from '@monorepo/shared/lib/utils';
import { AcceptanceStateStatus, Calculation, CartItem, TranslationType } from '@monorepo/types';

export function mapCartToAnalyticsProps({
	cartItems,
	acceptanceState,
	workfield,
	salesChannel,
}: {
	cartItems: CartItem[];
	acceptanceState?: AcceptanceStateStatus;
	workfield?: string;
	salesChannel?: string;
}) {
	let transaction_id = '';
	let value = 0;
	let tax = 0;

	const items = [];

	for (const cartItem of cartItems) {
		transaction_id = cartItem.guid;
		value += cartItem.priceAfterDiscount || cartItem.price;
		tax += cartItem.tax;

		for (const option of cartItem.items) {
			items.push({
				item_id: option.guid,
				item_name: option.name,
				item_brand: cartItem.brand,
				price: option.price,
				tax: option.tax,
				discount: option.discount || 0,
				...(option.promotion && {
					coupon: `${option.promotion}`,
				}),
			});
		}
	}

	return {
		transaction_id,
		value,
		tax,
		items,
		acceptance_state: acceptanceState,
		...(workfield && { workfield }),
		...(salesChannel && { salesChannel }),
	};
}

export const mapContentToShoppingCartProduct = ({
	calculation,
	t,
	isConfigure,
	isSummary,
}: {
	calculation: Calculation;
	t: TranslationType;
	isConfigure?: boolean;
	isSummary?: boolean;
}) => {
	return calculation.product.options?.map((option) => {
		const descriptions: Array<string | ReactNode> = [];
		let staticText = '';
		// TODO find a cleaner way to add content here
		if (isConfigure) {
			const insuredAmountDefault = option.specs.find((spec) => {
				return spec.is_insured_amount;
			});

			const insuredAmountCase = option.specs.find(
				(spec) => spec.is_insured_amount && spec.insured_amount_type === 'case'
			);

			const insuredAmountYear = option.specs.find(
				(spec) => spec.is_insured_amount && spec.insured_amount_type === 'year'
			);

			const insuredAmountDispute = option.specs.find(
				(spec) => spec.is_insured_amount && spec.insured_amount_type === 'dispute'
			);

			const rebuildValue = calculation.product.preconditions.find(
				(precondition) => precondition.reference === 'rebuild-value'
			);

			if (calculation.product.name === 'Inventaris en goederen' && insuredAmountDefault) {
				staticText = t('common.to', {
					amount: transformToCurrency(+insuredAmountDefault.value),
				});
			} else if (calculation.product.name === 'Elektronica' && insuredAmountDefault) {
				staticText = t('common.to', {
					amount: transformToCurrency(+insuredAmountDefault.value),
				});
			} else if (calculation.product.name === 'Bedrijfsaansprakelijkheid' && insuredAmountCase && insuredAmountYear) {
				staticText = t('common.perClaimPerYear', {
					amountPerClaim: transformToCurrency(+insuredAmountCase.value),
					amountPerYear: transformToCurrency(+insuredAmountYear.value),
				});
			} else if (calculation.product.name === 'Geld' && insuredAmountDefault) {
				staticText = t('common.to', {
					amount: transformToCurrency(+insuredAmountDefault.value),
				});
			} else if (calculation.product.name === 'Werknemersschade' && insuredAmountCase) {
				staticText = t('common.to', {
					amount: transformToCurrency(+insuredAmountCase.value),
				});
			} else if (calculation.product.name === 'Glas en lichtreclame' && insuredAmountDefault) {
				staticText = t('common.to', {
					amount: transformToCurrency(+insuredAmountDefault.value),
				});
			} else if (calculation.product.name === 'Spullen onderweg' && insuredAmountDefault) {
				staticText = t('common.to', {
					amount: transformToCurrency(+insuredAmountDefault.value),
				});
			} else if (calculation.product.name === 'Omzetverlies' && insuredAmountDefault) {
				staticText = t('common.to', {
					amount: transformToCurrency(+insuredAmountDefault.value),
				});
			} else if (calculation.product.name === 'Gebouw' && rebuildValue) {
				staticText = t('common.rebuildValue', {
					amount: transformToCurrency(+rebuildValue.answer),
				});
			} else if (calculation.product.name === 'Vangnet voor verweer' && insuredAmountDispute) {
				staticText = t('common.perDispute', {
					amount: transformToCurrency(+insuredAmountDispute.value),
				});
			}
		} else {
			const insuredAmountDefault = option.specs.find(
				(spec) =>
					spec.is_insured_amount &&
					(spec.insured_amount_type === 'default' || !spec.hasOwnProperty('insured_amount_type'))
			);

			if (insuredAmountDefault) {
				descriptions.push(
					t('page.funnel.compose.cart.insuredAmount', {
						insuredAmount: transformToCurrency(+insuredAmountDefault.value),
					})
				);
			}

			const insuredAmountDispute = option.specs.find(
				(spec) => spec.is_insured_amount && spec.insured_amount_type === 'dispute'
			);
			if (insuredAmountDispute) {
				descriptions.push(
					t('page.funnel.compose.cart.perDispute', {
						amount: transformToCurrency(+insuredAmountDispute.value),
					})
				);
			}

			const insuredAmountCase = option.specs.find(
				(spec) => spec.is_insured_amount && spec.insured_amount_type === 'case'
			);
			if (insuredAmountCase) {
				descriptions.push(
					t('page.funnel.compose.cart.perClaim', {
						claimPrice: transformToCurrency(+insuredAmountCase.value),
					})
				);
			}

			const insuredAmountYear = option.specs.find(
				(spec) => spec.is_insured_amount && spec.insured_amount_type === 'year'
			);
			if (insuredAmountYear) {
				descriptions.push(
					t('page.funnel.compose.cart.perYear', {
						claimPrice: transformToCurrency(+insuredAmountYear.value),
					})
				);
			}

			const insuredAmountDeceased = option.specs.find(
				(spec) => spec.is_insured_amount && spec.insured_amount_type === 'deceased'
			);
			if (insuredAmountDeceased) {
				descriptions.push(
					t('page.funnel.compose.cart.byDeceased', {
						claimPrice: transformToCurrency(+insuredAmountDeceased.value),
					})
				);
			}

			const insuredAmountDisability = option.specs.find(
				(spec) => spec.is_insured_amount && spec.insured_amount_type === 'disability'
			);

			if (insuredAmountDisability) {
				descriptions.push(
					t('page.funnel.compose.cart.byDisabled', {
						claimPrice: transformToCurrency(+insuredAmountDisability.value),
					})
				);
			}

			const deductible = option.specs.find(
				(spec) => spec.is_deductible && (spec.deductible_type === 'default' || spec.deductible_type === 'case')
			);

			if (deductible) {
				let deductibleText = t('page.funnel.compose.cart.ownRisk', {
					riskPrice: transformToCurrency(+deductible.value),
				});

				const deductibleTheft = option.specs.find((spec) => spec.is_deductible && spec.deductible_type === 'theft');

				if (deductibleTheft) {
					deductibleText += ' ';
					deductibleText += t('page.funnel.compose.cart.ownRiskTheft', {
						riskPrice: transformToCurrency(+deductibleTheft.value),
					});
				}
				descriptions.push(deductibleText);
			}

			const systemFailure = option.specs.find((spec) => spec.reference === 'additional_cyber_system-failures');
			if (systemFailure) {
				const systemFailureComp = (
					<Trans
						i18nKey={systemFailure.value === 'J' ? 'common.hasSystemFailure' : 'common.noSystemFailure'}
						components={{
							has: <span className='text-success300 font-medium' />,
							hasNot: <span />,
						}}
					/>
				);

				descriptions.push(systemFailureComp);
			}

			// ! TODO: This is a dirty fix for the Zorg Zeker Pakket quote products that are a one-off
			if (calculation.product.code === 'M-EXT-AO-0001') {
				const insuredAmount = calculation.product.preconditions.find(({ reference }) => reference === 'insured_amount');

				if (insuredAmount && insuredAmount?.answer !== null) {
					descriptions.push(
						<Trans
							i18nKey='page.funnel.compose.cart.insured'
							tOptions={{ price: transformToCurrency(+insuredAmount.answer) }}
						/>
					);
				}

				const premiumIndication = calculation.product.preconditions.find(
					({ reference }) => reference === 'premium-indication'
				);

				if (premiumIndication && premiumIndication?.answer !== null && !isSummary) {
					descriptions.push(
						<Trans
							i18nKey='page.funnel.compose.cart.indicativePerMonth'
							components={{
								amount: <span className='text-grayscale600 font-semibold' />,
							}}
							tOptions={{ price: transformToCurrency(+premiumIndication.answer) }}
						/>
					);
				}
			}
		}

		return {
			guid: option.guid,
			name: option.name,
			price: option.price.premium_after_discount,
			priceBeforeDiscount: option.price.premium_before_discount,
			promotion: option.price.promotion,
			tax: option.price.tax,
			staticText,
			descriptions,
		};
	});
};
