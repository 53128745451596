import cn from 'classnames';
import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { FC } from 'react';

import { BillingUnits, CartItem } from '@monorepo/types';

import { Icon, IconButton, InsuranceDocuments, ProductIcon } from '../../../Misc';
import { Text } from '../../../TextComponents';
import { DataEditCard } from '../DataEditCard/DataEditCard';
import { SummaryCardPrice } from '../SummaryCardPrice/SummaryCardPrice';
import { transformToCurrency } from './../../../../lib/utils';

export type SummaryCardItemVariants = 'configure' | 'compose';

export type SummaryCardItemProps = CartItem & {
	variant: SummaryCardItemVariants;
	onMoreInformation?: () => void;
	isB2B?: boolean;
	hasBundle?: boolean;
	billingUnit?: BillingUnits;
	showDocumentName?: boolean;
};

export const SummaryCardItem: FC<SummaryCardItemProps> = ({
	predictivePrice,
	name,
	icon,
	priceAfterDiscount,
	priceBeforeDiscount,
	risk_addresses = [],
	items,
	price,
	variant,
	billingUnit = 'monthly',
	documents,
	hasBundle = false,
	showDocumentName = true,
	isB2B = true,
	onMoreInformation,
}) => {
	const { t } = useTranslation(['common']);
	const isPetIcon = icon === 'cat' || icon === 'dog';

	//! Dirty fix to not show certain details for quote products
	const isQuoteProduct = items.some(
		({ name }) => name.toLowerCase() === 'pensioen' || name.toLowerCase() === 'arbeidsongeschiktheid'
	);

	return (
		<li className='flex flex-col md:flex-row' data-testid={`summary-card-item-${name}`}>
			<div className='flex grow flex-col'>
				<div className='flex items-center'>
					<div
						className={classNames(
							'flex flex-1 flex-row items-center space-x-8 md:space-x-12',
							!isPetIcon && 'sm:items-start'
						)}>
						{isPetIcon ? (
							<ProductIcon name={icon} />
						) : (
							<Icon color='grayscale600' className='leading-none' name={icon} />
						)}
						<Text variant='body-m' color='grayscale600' weight='medium'>
							{name}
						</Text>
					</div>
					{onMoreInformation && (
						<IconButton
							className='ml-auto md:hidden'
							color='info300'
							iconName='info-circle'
							onClick={onMoreInformation}
						/>
					)}
				</div>

				{variant === 'compose' &&
					((items && items.length > 0 && !isQuoteProduct) ||
						(isQuoteProduct && items.flatMap(({ descriptions }) => descriptions).length > 0)) && (
						<div className='mt-8 space-y-16'>
							{items.map(({ name: optionName, descriptions }, index) => {
								return (
									<div key={`summary-item-${index}`}>
										{/* Only show the name of 'dekking' if it differs from the productname  */}
										{optionName !== name && optionName.toLowerCase() !== 'arbeidsongeschiktheid' && (
											<Text variant='body-s' color='grayscale500' weight='medium'>
												{optionName}
											</Text>
										)}
										{descriptions?.map((description, index2) => (
											<div key={`summary-item-description-${description}-${index2}`} className='mt-4'>
												<Text variant='body-s' color='grayscale400'>
													{description}
												</Text>
											</div>
										))}
									</div>
								);
							})}
						</div>
					)}

				{variant === 'compose' && risk_addresses.length > 0 && (
					<div className='mt-12 space-y-16'>
						<Text variant='body-s' color='grayscale500' weight='semibold'>
							{t('components.summaryCard.item.riskAddress')}
						</Text>

						{risk_addresses.map((address) => (
							<DataEditCard key={`risk-address-${address.street}`} address={address} />
						))}
					</div>
				)}

				{variant === 'compose' && (
					<div
						className={cn(
							variant === 'compose' ? 'mt-12' : 'mt-4',
							hasBundle && documents ? 'sm:hidden' : 'md:hidden'
						)}>
						{hasBundle && documents ? (
							<div className=' flex flex-row flex-wrap space-x-8'>
								<InsuranceDocuments productDocument={documents} showDocumentName={showDocumentName} />
							</div>
						) : (
							<SummaryCardPrice
								billingUnit={billingUnit}
								price={price}
								priceAfterDiscount={priceAfterDiscount}
								priceBeforeDiscount={priceBeforeDiscount}
							/>
						)}
					</div>
				)}

				{variant === 'compose' && documents && !hasBundle && documents.documents.length > 0 && (
					<div className={cn(isB2B && 'mt-24', !isB2B && 'mt-8', 'flex flex-row flex-wrap space-x-8')}>
						<InsuranceDocuments productDocument={documents} showDocumentName={showDocumentName} />
					</div>
				)}
			</div>
			{variant === 'configure' && (
				<div className={cn('pt-4 md:pl-8 md:pt-0 md:text-right', onMoreInformation && 'flex items-center space-x-8')}>
					<Text variant='body-s' color='grayscale400'>
						{items[0]?.staticText}
					</Text>
					{onMoreInformation && (
						<IconButton
							className='ml-auto max-md:hidden'
							color='info300'
							iconName='info-circle'
							onClick={onMoreInformation}
						/>
					)}
				</div>
			)}

			{variant === 'compose' && (
				<div
					className={cn(
						hasBundle && documents ? 'max-md:mt-8 max-sm:hidden' : 'pl-8 max-md:hidden',
						isPetIcon && 'content-center'
					)}>
					{hasBundle && documents ? (
						<div className=' flex flex-row flex-wrap space-x-8'>
							<InsuranceDocuments
								productDocument={documents}
								showDocumentName={showDocumentName}
								documentContainerClassName='md:justify-end'
							/>
						</div>
					) : predictivePrice ? (
						<Text variant='body-s' color='grayscale400'>
							<Trans
								i18nKey='components.summaryCard.item.indicativePerMonth'
								components={{
									amount: <span className='text-grayscale600 font-semibold' />,
								}}
								tOptions={{ price: transformToCurrency(predictivePrice) }}
							/>
						</Text>
					) : !isQuoteProduct ? (
						<SummaryCardPrice
							billingUnit={billingUnit}
							price={price}
							priceAfterDiscount={priceAfterDiscount}
							priceBeforeDiscount={priceBeforeDiscount}
						/>
					) : null}
				</div>
			)}
		</li>
	);
};
